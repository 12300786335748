export default {
  getOption (id) {
    return window[`__grattisFullscreenEnable_${id}`]
  },
  setOption (id, value) {
    window[`__grattisFullscreenEnable_${id}`] = value
  },
  getAutoInit () {
    return window.sessionStorage.getItem('grattis-autofullscreen-init')
  },
  setAutoInit (val) {
    return window.sessionStorage.setItem('grattis-autofullscreen-init', val.toString())
  }
}
