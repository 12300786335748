'use strict'

import ajax from './helpers/ajax'
import cnst from '../constant'

export default {
  /**
   * Получение настроек скролл бара
   * @param {number} version - версия кеша
   * @param {function} cb
   */
  getScrollBarSettings (version, cb) {
    ajax.get(cnst.HOST + '/api/widgets/scroll-bar-settings?s=' + version, cb)
  }
}
