import cnst from '../../../constant'
import scrollBar from '../../scroll-bar'

export default function (data) {
  if (data.type === cnst.WIDGET_BAR_IFRAME.SCROLL) {
    if (data.additionalData && Object.keys(data.additionalData).includes('handleClick')) {
      scrollBar.handleClickOutsideScrollbar(data.additionalData.handleClick ? 'open' : 'close')
      return
    }

    scrollBar.scrollToWidget(data.additionalData)
  }
}
