import helpers from './helpers'
import cnst from '../constant'

function flushCacheVersion () {
  let d = Date.now()
  d = d / 1000
  d = d / 60
  d = d / 10
  d = Math.trunc(d)
  return d * 10 * 60 * 1000
}

export default function (widgetId, cb) {
  const version = flushCacheVersion()

  helpers.ajax.get(cnst.HOST + '/api/widgets/' + widgetId + '/cache?v=' + version, function (err, data) {
    if (err) {
      console.log(err)
      return cb(err)
    }
    cb(null, data)
  })
}
