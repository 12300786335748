import bar from './bar'
import isRun from './is-run'
import getData from './get-data'
import stop from './stop'

/**
 * Флаг проверки ушел ли widget из поля зрения пользователя,
 * при прокрутке вниз
 * @type {boolean}
 */
let isShowScrollBar = false

/**
 * Ссылка на iframe widget который запустил скролл бар
 * @param {HTMLIFrameElement} widgetIframe
 * @returns {(function(): void)|*}
 */
export default function (widgetIframe) {
  return function () {
    function scrollEventHandler () {
      if (!isRun()) return
      const rect = widgetIframe.getBoundingClientRect()
      /**
       * виджет скрылся при скролле?
       * @type {boolean}
       */
      const isHiddeWidget = rect.bottom < 0

      if (isHiddeWidget !== isShowScrollBar) {
        isShowScrollBar = isHiddeWidget
        // Надо показать бар
        if (isShowScrollBar) {
          const { settings } = getData()
          // Устанавливаем таймаут появления бара
          setTimeout(function () {
            bar.toggleShow(true)
            // Если есть таймаут закрытия
            if (settings.timeoutClose) {
              // Запускаем таймаут отложенного закрытия
              setTimeout(function () {
                stop()
              }, settings.timeoutClose)
            }
          }, settings.timeoutShow)
        } else {
          bar.toggleShow(false)
        }
      }
    }
    scrollEventHandler()
    window.addEventListener('scroll', scrollEventHandler)
  }
}
