export default function (func, delay) {
  let clearTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(clearTimer)
    clearTimer = setTimeout(() => {
      return func.apply(context, args)
    }, delay)
  }
}
