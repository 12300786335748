/* eslint-disable */
import cnst from '../../../constant'
import scrollBar from '../../scroll-bar'

export default function (data) {
  if (data.type === cnst.WIDGET_IFRAME.IS_CLICK) {
    // Останавлием показ скролл бара
    scrollBar.stop()
    // После взаимодействия с виджетом,
    // проверяем надо ли показать JivoSite chat
    // и только если он не был загружен
    if (data.message && data.message.isShowChatSupport && !window.jivo_api) {
      /* Jivosite chat script */
      (function(){ var widget_id = 'iQzU6zHurZ';var d=document;var w=window;function l(){
        var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true;
        s.src = '//code.jivosite.com/script/widget/'+widget_id; var ss = document.getElementsByTagName('script')[0]; ss.parentNode.insertBefore(s, ss);}
        if(d.readyState=='complete'){l();}else{if(w.attachEvent){w.attachEvent('onload',l);}
        else{w.addEventListener('load',l,false);}}})();
      /* End Jivosite chat script */
    }
  }
}
