import ajax from './ajax'
import debounce from './debounce'
import base64 from './encode-base64'
import getUrlParameter from './get-url-parameter'
import scrollTo from './scroll-to'
import getIosVersion from './get-ios-version'

export default {
  ajax,
  base64,
  debounce,
  getUrlParameter,
  scrollTo,
  getIosVersion
}
