import cnst from '../../constant'

/**
 * Ссылка на созданный iframe бара
 * @type {HTMLIFrameElement | null}
 */
let scrollBarIframe = null
/**
 * Ссылка на подложку под iframe бара
 * @type {HTMLDivElement | null}
 */
let scrollBarBottom = null

/**
 * Высота скролл бара
 * @type {string}
 */
let scrollBarHeight = '192px'

export default {
  /**
   * Создаем html представление бара
   * iframe и подложку под него (чтобы бар внизу страницы не перекрывал данные сайта)
   * @param {number} categoryId
   * @param {number|null} nameId
   * @param {number} versionStatic
   * @param {boolean?} useTimer
   * @param {number} timerValue
   */
  load (categoryId, nameId, versionStatic, useTimer = true, timerValue = 7) {
    if (scrollBarIframe) return
    if (window && window.innerWidth < 800) {
      scrollBarHeight = '260px'
    }

    const styleElement = document.createElement('style')
    styleElement.innerHTML = `
    @-webkit-keyframes grattis-roll-in-blurred-left {
      0% {
        -webkit-transform: translateX(-1000px) rotate(-720deg);
                transform: translateX(-1000px) rotate(-720deg);
        -webkit-filter: blur(50px);
                filter: blur(50px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0) rotate(0deg);
                transform: translateX(0) rotate(0deg);
        -webkit-filter: blur(0);
                filter: blur(0);
        opacity: 1;
      }
    }
    @keyframes grattis-roll-in-blurred-left {
      0% {
        -webkit-transform: translateX(-1000px) rotate(-720deg);
                transform: translateX(-1000px) rotate(-720deg);
        -webkit-filter: blur(50px);
                filter: blur(50px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0) rotate(0deg);
                transform: translateX(0) rotate(0deg);
        -webkit-filter: blur(0);
                filter: blur(0);
        opacity: 1;
      }
    }

    @-webkit-keyframes grattis-fade-out-bottom{0%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}100%{-webkit-transform:translateY(50px);transform:translateY(50px);opacity:0}}@keyframes grattis-fade-out-bottom{0%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}100%{-webkit-transform:translateY(50px);transform:translateY(50px);opacity:0}}
    `
    document.body.appendChild(styleElement)

    scrollBarIframe = document.createElement('iframe')
    scrollBarIframe.style.display = 'none'
    scrollBarIframe.style.height = scrollBarHeight
    scrollBarIframe.style.border = '0'
    scrollBarIframe.style.width = '100%'
    scrollBarIframe.style['min-width'] = '100%'
    scrollBarIframe.style['*width'] = '100%'
    scrollBarIframe.style.position = 'fixed'
    scrollBarIframe.style.bottom = '0'
    scrollBarIframe.style.left = '0'
    scrollBarIframe.style.right = '0'
    scrollBarIframe.style['box-shadow'] = 'rgb(133 103 35 / 50%) 0px 0px 33px'
    scrollBarIframe.style['border-radius'] = '16px 16px 0 0'
    scrollBarIframe.style.zIndex = '2147483647'
    scrollBarIframe.setAttribute('align', 'middle')
    scrollBarIframe.setAttribute('frameborder', 'no')
    scrollBarIframe.setAttribute('scrolling', 'no')
    scrollBarIframe.setAttribute('allowfullscreen', '')
    scrollBarIframe.setAttribute('allowtransparency', '')
    scrollBarIframe.setAttribute('id', 'grattisWidgetScrollBar')

    let url = `${cnst.HOST_SCROLL_BAR}/scroll-bar?categoryId=${categoryId}`
    // Конкретно имя выбрано в настройках виджета
    // может быть:
    // number > 0 - конкретное имя
    // null - не именная категория
    // -1 - все имена отображаются
    // -2 - мужские
    // -3 - женские
    if (nameId && nameId > 0) {
      url += `&nameId=${nameId}`
    }
    url += `&s=${versionStatic}`
    if (useTimer) {
      url += `&uttc=${useTimer}&tval=${timerValue}`
    }

    scrollBarIframe.setAttribute('src', url)
    document.body.appendChild(scrollBarIframe)

    scrollBarBottom = document.createElement('div')
    scrollBarBottom.setAttribute('id', 'grattisWidgetScrollBarH')
    scrollBarBottom.style.display = 'none'
    scrollBarBottom.style.height = scrollBarHeight
    scrollBarBottom.style.border = '0'
    scrollBarBottom.style.width = '100%'
    scrollBarBottom.style.position = 'relative'
    document.body.appendChild(scrollBarBottom)
  },
  /**
   * Переключатель отображения бара
   * @param {boolean} isShow
   */
  toggleShow (isShow) {
    if (!scrollBarIframe) {
      scrollBarIframe = document.querySelector('#grattisWidgetScrollBar')
    }
    if (!scrollBarIframe) return
    if (!scrollBarBottom) {
      scrollBarBottom = document.querySelector('#grattisWidgetScrollBarH')
    }

    if (isShow) {
      scrollBarIframe.style.display = 'block'
      scrollBarBottom.style.display = 'block'
      scrollBarIframe.style.animation = 'grattis-roll-in-blurred-left 0.65s cubic-bezier(0.230, 1.000, 0.320, 1.000) both'
      setTimeout(() => {
        scrollBarIframe.contentWindow.postMessage({
          type: '__grattis-scrollbar',
          status: 'start'
        }, '*')
      }, 300)
    } else {
      scrollBarIframe.style.animation = 'grattis-fade-out-bottom .3s cubic-bezier(.25,.46,.45,.94) both'
      setTimeout(() => {
        scrollBarIframe.style.display = 'none'
        scrollBarBottom.style.display = 'none'
        scrollBarIframe.contentWindow.postMessage({
          type: '__grattis-scrollbar',
          status: 'stop'
        }, '*')
      }, 300)
    }
  }
}
