// Значения, которые начинаются с __ будут заменены из конфига
// см. config
// Ключ для поиска установленных виджетов
const KEY = '__KEY'
// Подключение к серверу
const HOST = '__HOST'
// Домен скролл бара
const HOST_SCROLL_BAR = '__HOST_SCROLL_BAR'

export default {
  KEY,
  HOST,
  HOST_SCROLL_BAR,
  WIDGET_IFRAME: {
    LOAD_SCROLL_BAR: 'widget-load-scroll-bar', // Загрузить скролл бар
    IS_CLICK: 'widget-is-click', // Было взаимодействие с виджетом
    FULLSCREEN: 'widget-fullscreen-handle', // Передача данных для переключения режима
    MESSAGE_SCROLL_TO_WIDGET: 'message-scroll-to-widget', // Сообщение iframe о скролле к виджету,
    TITLE_TOP_IMAGE_CLICK: 'widget-title-top-image-click' // Клик по картинке-заголовку (нужно для метрики)
  },
  WIDGET_BAR_IFRAME: {
    CLOSE: 'widget-bar-close', // Закрыть скролл бар
    SCROLL: 'widget-bar-scroll-to-widget' // Скролим к виджету
  },
  FULLSCREEN_WORKING_WIDTH: 800, // ширина до которой устройство считается мобилкой
  IS_FULLSCREEN_ENABLED_OPTION: false, // параметр фуллскрина по умолчанию,
  LOAD_JIVO: 'load-jivo'
}
