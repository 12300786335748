import cnst from '../../../constant'
import scrollBar from '../../scroll-bar'

export default function (data) {
  // получили данные категории и запущен скролл бар
  if (data.type === cnst.WIDGET_IFRAME.LOAD_SCROLL_BAR && scrollBar.isRun()) {
    const { id } = scrollBar.getData()
    // загружены данные нужного виджета
    if (data.id === id) {
      const { categoryId, nameId, versionStatic, useTimer, timerValue } = data.message
      scrollBar.bar.load(categoryId, nameId, versionStatic, useTimer, timerValue)
    }
  }
}
