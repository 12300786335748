import eventScrollBar from './event-scroll-bar'
import eventWidget from './event-widget'
import cnst from '../../constant'
import fullscreen from '../fullscreen'
import { get, set } from './message-iframe-data'

if (!window.__grattisWidgetMessageIframe) {
  window.__grattisWidgetMessageIframe = {}
}

// События которые должны сработать один раз
// Так как на странице может быть загружено несколько виджетов
// Сообщения могут прийти несколько раз один и те же
const widgetEventOneLoad = [
  cnst.WIDGET_IFRAME.IS_CLICK,
  cnst.WIDGET_IFRAME.LOAD_SCROLL_BAR,
  cnst.WIDGET_BAR_IFRAME.CLOSE
]

export default {
  init () {
    window.addEventListener('message', this.onMessage, false)
  },
  onMessage (event) {
    const data = event.data

    if (!data || !data.type) {
      return
    }

    if (fullscreen.getOption(data.id)) {
      eventWidget.eventRunFullscreen(data)
    }

    if (widgetEventOneLoad.includes(data.type)) {
      // проверяем уже было?
      const isCheck = get(data.type, data.id)
      // если событие уже было шлем лесом
      if (isCheck) return
      // сохраняем
      set(data.type, data.id, data.message)
    }

    eventWidget.eventLoadScrollBar(data)
    eventWidget.eventFirstClick(data)
    eventWidget.eventJivo(data)

    eventScrollBar.eventBtnClose(data)
    eventScrollBar.eventScrollToWidget(data)
  }
}
