export default function (currentWidth) {
  let width = currentWidth >= 720 ? 720 : currentWidth
  if (currentWidth < 720 && currentWidth > 580) {
    width = 590
  }

  if (currentWidth < 580 && currentWidth > 500) {
    width = 510
  }

  if (currentWidth < 500 && currentWidth > 400) {
    width = 410
  }

  if (currentWidth <= 400) {
    width = 400
  }
  return width
}
