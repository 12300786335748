import helpers from '../helpers'
import bar from './bar'
import checkShow from './check-show'
import isRun from './is-run'
import getData from './get-data'
import stop from './stop'
import cnst from '../../constant'
import { handleClickOutsideScrollbar } from './parent-click'

// чтобы не затереть данные
if (!window.__grattisScrollBarWidgetData) {
  /**
   * Данные скролл бара
   * @type {object | null}
   * @private
   */
  window.__grattisScrollBarWidgetData = null
}

export default {
  /**
   * Физическое представление бара,
   * методы по работе с html
   */
  bar,
  /**
   * Обработчик события для скролла,
   * в нем мы проверим на возможно показа Бара
   */
  checkShow,
  /**
   * Запуск Бара
   * тут мы сохраняем начальные данные
   * @param data
   */
  run (data) {
    // должен быть только один виджет, самый первый
    if (!window.__grattisScrollBarWidgetData) {
      window.__grattisScrollBarWidgetData = data
    }
  },
  isRun,
  getData,
  /**
   * Запоминаем настройки скролл бара
   * @param {Object} settings
   * @param {number|0} settings.timeoutShow - отсрочка показа скролл бара (миллисекунды)
   * @param {number|null} settings.timeoutClose - отсрочка закрытия бара (null - не закрываем) (миллисекунды)
   */
  setSettings (settings) {
    if (window.__grattisScrollBarWidgetData) {
      window.__grattisScrollBarWidgetData.settings = settings
    }
  },
  /**
   * Метод скролла к виджету после клика на Бар
   * @param additionalData? - дополнительные параметры для работы интерактивного скроллбара
   */
  scrollToWidget (additionalData) {
    const { id, iframe } = this.getData()
    helpers.scrollTo(iframe, 500, () => {
      iframe.contentWindow.postMessage({
        id,
        type: cnst.WIDGET_IFRAME.MESSAGE_SCROLL_TO_WIDGET,
        message: additionalData || {}
      }, '*')
      this.stop()
    })
  },
  /**
   * Пользователь нажал на крестик в Scroll Bar.
   * Останавливаем и больше не показываем бар в рамках данного сайта
   */
  clickBtnClose () {
    window.localStorage.setItem('grattis_scroll_bar_btn_close', 'yes')
    bar.toggleShow(false)
    window.__grattisScrollBarWidgetData = null
  },
  /**
   * Не показывается по воле пользователя?
  */
  isClickBtnClose () {
    return !!window.localStorage.getItem('grattis_scroll_bar_btn_close')
  },
  stop,
  /**
   * Когда пользовать нажал на виджет запустился метод stop,
   * где мы остановили показ бара и пометили дату и время события
   * тут мы проверяем прошел ли час с данного момента
   */
  isStopExpired () {
    let clickAt = window.localStorage.getItem('grattis_scroll_bar_click_widget_at')
    if (!clickAt) return true
    clickAt = parseInt(clickAt)
    return Date.now() - clickAt > 1000 * 60 * 60
  },

  handleClickOutsideScrollbar
}
