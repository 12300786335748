export default {
  alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
  ie: /MSIE /.test(navigator.userAgent),
  encode: function (s) {
    /* jshint bitwise:false */
    const buffer = this.toUtf8(s)
    let position = -1
    let result
    const len = buffer.length
    let nan0
    let nan1
    let nan2
    // eslint-disable-next-line no-sparse-arrays,comma-spacing
    const enc = [,,,]

    if (this.ie) {
      result = []
      while (++position < len) {
        nan0 = buffer[position]
        nan1 = buffer[++position]
        enc[0] = nan0 >> 2
        enc[1] = ((nan0 & 3) << 4) | (nan1 >> 4)
        if (isNaN(nan1)) enc[2] = enc[3] = 64
        else {
          nan2 = buffer[++position]
          enc[2] = ((nan1 & 15) << 2) | (nan2 >> 6)
          enc[3] = isNaN(nan2) ? 64 : nan2 & 63
        }
        result.push(
          this.alphabet.charAt(enc[0]),
          this.alphabet.charAt(enc[1]),
          this.alphabet.charAt(enc[2]),
          this.alphabet.charAt(enc[3])
        )
      }
      return result.join('')
    } else {
      result = ''
      while (++position < len) {
        nan0 = buffer[position]
        nan1 = buffer[++position]
        enc[0] = nan0 >> 2
        enc[1] = ((nan0 & 3) << 4) | (nan1 >> 4)
        if (isNaN(nan1)) enc[2] = enc[3] = 64
        else {
          nan2 = buffer[++position]
          enc[2] = ((nan1 & 15) << 2) | (nan2 >> 6)
          enc[3] = isNaN(nan2) ? 64 : nan2 & 63
        }
        result +=
          this.alphabet[enc[0]] +
          this.alphabet[enc[1]] +
          this.alphabet[enc[2]] +
          this.alphabet[enc[3]]
      }
      return result
    }
  },
  toUtf8: function (s) {
    /* jshint bitwise:false */
    let position = -1
    const len = s.length
    let chr
    const buffer = []
    // eslint-disable-next-line no-control-regex
    if (/^[\x00-\x7f]*$/.test(s)) {
      while (++position < len) buffer.push(s.charCodeAt(position))
    } else {
      while (++position < len) {
        chr = s.charCodeAt(position)
        if (chr < 128) buffer.push(chr)
        else if (chr < 2048) buffer.push((chr >> 6) | 192, (chr & 63) | 128)
        else {
          buffer.push(
            (chr >> 12) | 224,
            ((chr >> 6) & 63) | 128,
            (chr & 63) | 128
          )
        }
      }
    }
    return buffer
  }
}
