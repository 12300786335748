/* eslint-disable */
import cnst from '../../../constant'
import scrollBar from '../../scroll-bar'

export default function (data) {
  if (data.type === cnst.LOAD_JIVO) {
    // Останавлием показ скролл бара
    scrollBar.stop()

    let intervalId = null
    // Колбек обработчика события load для скрипта jivo
    function onJivoScriptLoaded() {
      // Дожидаемся загрузки инстанса jivo
      intervalId = setInterval(() => {
        if (window.jivo_api) {

          // Выводим кастомное сообщение
          window.jivo_api.showProactiveInvitation(data.message.text)
          clearInterval(intervalId)
        }
      }, 1000);
    }
    if (data.message && !window.jivo_api) {
      /* Jivosite chat script */
      (function(){ var widget_id = 'iQzU6zHurZ';var d=document;var w=window;function l(){
        var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true;
        s.src = '//code.jivosite.com/script/widget/'+widget_id;
        s.onload = onJivoScriptLoaded
        var ss = document.getElementsByTagName('script')[0]; ss.parentNode.insertBefore(s, ss);}
        if(d.readyState=='complete'){l();}else{if(w.attachEvent){w.attachEvent('onload',l);}
        else{w.addEventListener('load',l,false);}}})();
      /* End Jivosite chat script */
    }
  }
}
