import getWidth from './get-width'
import helpers from './helpers'
import autoWidget from './auto-widget'
import getUtmUrl from './get-utm-url'
import loadWidget from './load-widget'
import cnst from '../constant'
import scrollBar from './scroll-bar'
import setupContainer from './setup-container'
import getCacheVersion from './get-cache-version'

export default function (id, container) {
  container = setupContainer(container)
  const currentWidth = container.offsetWidth || window.innerWidth
  const width = getWidth(currentWidth)

  getCacheVersion(id, function (err, data) {
    if (err) {
      console.log(err)
      return
    }

    // Показываем над виджетом картинку?
    if (data.ttImg) {
      const img = document.createElement('img')
      img.src = 'https://cdn.grattis.ru/grattis/widget-title-top/default.png'
      img.style.width = '100%'
      img.style['max-width'] = '600px'
      img.style.display = 'block'
      img.style['margin-left'] = 'auto'
      img.style['margin-right'] = 'auto'
      img.style['margin-bottom'] = '10px'
      container.parentNode.insertBefore(img, container)
      img.onclick = () => {
        const widget = document.querySelector(`div.gw_${id}`)

        widget.lastChild.contentWindow.postMessage({
          id,
          type: cnst.WIDGET_IFRAME.TITLE_TOP_IMAGE_CLICK,
          message: {
            event: 'click'
          }
        }, '*')
      }
    }

    let url = cnst.HOST + '/widgets/' + id +
      '/?v=' + data.v +
      '&s=' + data.s +
      '&w=' + width +
      // referrer
      '&r=' + encodeURIComponent(window.location.href)

    // автоматическое определение категории?
    if (data.a) {
      url += '&u=' + autoWidget.getUrl()
      url += '&t=' + autoWidget.getTitle()
    }

    url += getUtmUrl()

    // Показываем "скролл бар"?
    const isScrollBar = !!data.sb && !scrollBar.isClickBtnClose() && scrollBar.isStopExpired()

    let height = '565px'
    // title top
    if (data.tt) {
      url += '&tt=1'
      height = '635px'
    }

    const widgetOptions = {
      height,
      isScrollBar,
      isShowHits: true,
      versionStatic: data.s,
      isShowPreloader: data.sp,
      isFullscreenEnabled: data.fm,
      isAutoFullscreenEnabled: data.afe,
      useTimerToClose: data.uttc
    }

    // Проверка на загрузку
    if (data.c) {
      helpers.ajax.head(url, function (err) {
        if (err) {
          console.log(err)
          return
        }
        loadWidget(id, container, url, widgetOptions)
      })
    } else {
      loadWidget(id, container, url, widgetOptions)
    }
  })
}
