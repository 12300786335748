/**
 * Will gracefully scroll the page
 * This function will scroll the page using
 * an `ease-in-ou` effect.
 *
 * You can use it to scroll to a given element, as well.
 * To do so, pass the element instead of a number as the position.
 * Optionally, you can pass a `queryString` for an element selector.
 *
 * The default duration is half a second (500ms)
 *
 * This function returns a Promise that resolves as soon
 * as it has finished scrolling. If a selector is passed and
 * the element is not present in the page, it will reject.
 *
 * EXAMPLES:
 *
 * ```js
 * window.grattisScrollTo('#some-section', 2000);
 * window.grattisScrollTo(document.getElementById('some-section'), 1000);
 * window.grattisScrollTo(500); // will scroll to 500px in 500ms
 * ```
 *
 * @param {HTMLElement|Number} to
 * @param {Number} duration [default=500]
 * @param {Function?} cb
 *
 * Inspired by @and-josh's work
 */
function scrollTo (to, duration, cb) {
  if (!duration) duration = 500
  if (!cb) cb = function () {}
  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = function (t, b, c, d) {
    t /= d / 2
    if (t < 1) return c / 2 * t * t + b
    t--
    return -c / 2 * (t * (t - 2) - 1) + b
  }

  // По умолчанию думаем что это корневой элемент
  let element = document.documentElement

  // Но если мы можем получить scrollingElement
  if (('scrollingElement' in document)) {
    element = document.scrollingElement
  }

  if (typeof to === 'string') {
    to = document.querySelector(to)
  }
  if (typeof to !== 'number') {
    const height = Math.floor(window.innerHeight / 2)
    const rect = to.getBoundingClientRect()
    to = rect.top + element.scrollTop - Math.floor((rect.top - rect.bottom) / 2) - height
  }

  const start = element.scrollTop
  const change = to - start
  let currentTime = 0
  const increment = 20

  const animateScroll = function () {
    currentTime += increment
    element.scrollTop = easeInOutQuad(currentTime, start, change, duration)
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    } else {
      cb()
    }
  }
  animateScroll()
}

export default scrollTo
