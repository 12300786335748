function set (type, widgetId, data, force = false) {
  const KEY = widgetId + '_' + type
  if (force || !window.__grattisWidgetMessageIframe[KEY]) {
    window.__grattisWidgetMessageIframe[KEY] = data
  }
}

function get (type, widgetId) {
  const KEY = widgetId + '_' + type
  return window.__grattisWidgetMessageIframe[KEY]
}

export { get, set }
