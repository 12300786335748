function grattisAjax (method, url, cb) {
  const request = new XMLHttpRequest()
  request.onreadystatechange = function () {
    if (request.readyState !== 4) return
    if (request.status === 200) {
      let data = null
      if (request.responseText) {
        try {
          data = JSON.parse(request.responseText)
        } catch (err) {
          return cb(err)
        }
      }
      cb(null, data)
    } else {
      cb(new Error(request.status))
    }
  }
  request.open(method, url, true)
  request.send()
}

export default {
  get: function (url, cb) {
    grattisAjax('GET', url, cb)
  },
  head: function (url, cb) {
    grattisAjax('HEAD', url, cb)
  }
}
