import bar from './bar'
import isRun from './is-run'

/**
 * Когда пользователь провзаимодействовал с любым виджетом
 * либо наступил таймаут к закрытию см. settings.timeoutClose
 * => останавливаем показ бара на час
 */
export default function stop () {
  if (isRun()) {
    window.localStorage.setItem('grattis_scroll_bar_click_widget_at', Date.now().toString())
    bar.toggleShow(false)
    window.__grattisScrollBarWidgetData = null
  }
}
