export default function (container) {
  container.style.position = 'relative'
  container.style.display = 'block'
  container.style.overflow = 'hidden'
  container.style.margin = '0'
  container.style.padding = '0'
  container.style['text-align'] = 'center'
  container.style.width = '100%'
  container.style.height = '0px'
  return container
}
