import { getBody, getText } from './get-preloader'

export default function (color) {
  const preloader = getBody(color)
  const text = getText('#000')
  const wrapper = document.createElement('div')
  const styles = {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    'justify-content': 'center',
    'align-items': 'center',
    'flex-direction': 'column'
  }
  Object.keys(styles).forEach((key) => {
    wrapper.style.setProperty(key, styles[key])
  })
  wrapper.appendChild(preloader)
  wrapper.appendChild(text)

  return wrapper
}
