export default function (userAgent) {
  const re = /(iPad OS |iPhone OS |iphone OS |iPod OS )((\d+_?){2,3})\s/

  const match = userAgent.match(re)
  if (!match) return null
  // const version = +match[2].replace('_', '.')
  const version = +match[2].split('_')[0]
  if (typeof version !== 'number') return null
  return version
}
