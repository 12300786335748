import getUrlParam from './helpers/get-url-parameter'

export default function () {
  let url = ''
  // Получаем UTM метки из URL страницы где установлено промо
  // И передаем как часть урл страницы промо
  const utmSource = getUrlParam('utm_source')
  if (utmSource) {
    url += '&utm_source=' + utmSource
  }

  const utmMedium = getUrlParam('utm_medium')
  if (utmMedium) {
    url += '&utm_medium=' + utmMedium
  }

  const utmCampaign = getUrlParam('utm_campaign')
  if (utmCampaign) {
    url += '&utm_campaign=' + utmCampaign
  }

  const utmContent = getUrlParam('utm_content')
  if (utmContent) {
    url += '&utm_content=' + utmContent
  }

  const utmTerm = getUrlParam('utm_term')
  if (utmTerm) {
    url += '&utm_term=' + utmTerm
  }

  return url
}
