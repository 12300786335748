import hintsCheckShow from './hints-check-show'
import scrollBar from './scroll-bar'
import api from './api'
import { getPreloaderWrapper } from './preloader'
import cnst from '../constant'
import fullscreen from './fullscreen'

const defaultOptions = {
  height: '565px',
  isScrollBar: false,
  isShowHits: false,
  versionStatic: 0,
  isShowPreloader: false,
  isFullscreenEnabled: cnst.IS_FULLSCREEN_ENABLED_OPTION,
  useTimerToClose: true
}

/**
 * Загрузка виджета
 * @param {string} widgetId
 * @param {HTMLDivElement} container - контейнер в котором загрузиться виджет (iframe)
 * @param {string} url - путь для загрузки виджета
 * @param options? - настройки загрузки
 * @param {string?} options.height - высота виджета
 * @param {boolean?} options.isScrollBar - виджет запускает скролл бар?
 * @param {boolean?} options.isShowHits - виджет показывает подсказки?
 * @param {number?} options.versionStatic - виджет показывает подсказки?
 * @param {boolean?} options.isShowPreloader - виджет показывает прелоадер?
 */
export default function (widgetId, container, url, options) {
  if (!options) {
    options = defaultOptions
  } else {
    options = { ...defaultOptions, ...options }
  }

  let preloader = null
  if (options.isShowPreloader) {
    preloader = getPreloaderWrapper('#93bfec')
  }
  const iframe = document.createElement('iframe')
  iframe.style.height = '100%'
  iframe.style.border = '0'
  iframe.style.width = '1px'
  iframe.style.position = 'absolute'
  iframe.style.left = '0'
  iframe.style.right = '0'
  iframe.style['min-width'] = '100%'
  iframe.style['z-index'] = '1'
  iframe.style['*width'] = '100%'
  iframe.setAttribute('align', 'middle')
  iframe.setAttribute('frameborder', 'no')
  iframe.setAttribute('scrolling', 'no')
  iframe.setAttribute('allowfullscreen', '')
  iframe.setAttribute('allowtransparency', '')
  iframe.setAttribute('src', url)
  container.appendChild(iframe)
  if (preloader) {
    container.appendChild(preloader)
  }
  container.style.height = options.height

  if (options.isShowHits) {
    // Тут задаем обработчик для подсказок когда виджет попадает в фокус
    iframe.addEventListener('load', () => {
      hintsCheckShow(iframe)
    })
  }

  if (preloader) {
    iframe.addEventListener('load', () => {
      preloader.remove()
    })
  }

  // Если этот виджет имеет скролл бар и
  // не было еще ни одного запущенного обработчика,
  // то запускаем обработчик после загрузки виджета
  // так как на странице должен быть только один скролл бар
  if (options.isScrollBar && !scrollBar.isRun()) {
    // запускаем
    scrollBar.run({
      id: widgetId,
      iframe
    })
    // получаем данные настроек для скролл бара
    api.getScrollBarSettings(options.versionStatic, function (err, settings) {
      if (err) {
        return console.log(err)
      }
      scrollBar.setSettings(settings)
      iframe.addEventListener('load', scrollBar.checkShow(iframe))
    })
  }

  // сохраняем значение возможности запуска фуллскрина
  fullscreen.setOption(widgetId, options.isFullscreenEnabled)

  // слушаем ресайз, чтобы обновлять высоту виджета при появлении/исчезновении панели инструментов в мобильном браузере
  if (options.isFullscreenEnabled) {
    const widget = document.querySelector(`div.gw_${widgetId}`)

    window.addEventListener('resize', () => {
      widget.lastChild.contentWindow.postMessage({
        id: widgetId,
        type: cnst.WIDGET_IFRAME.FULLSCREEN,
        message: {
          width: window.innerWidth,
          height: window.innerHeight
        }
      }, '*')
    })

    if (options.isAutoFullscreenEnabled && !fullscreen.getAutoInit()) {
      fullscreen.setAutoInit(true)
      iframe.addEventListener('load', () => {
        widget.lastChild.contentWindow.postMessage({
          id: widgetId,
          type: cnst.WIDGET_IFRAME.FULLSCREEN,
          message: {
            width: window.innerWidth,
            height: window.innerHeight,
            execute: 'enable',
            timerToClose: true
          }
        }, '*')
      })
    }
  }
}
