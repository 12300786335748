import constants from '../../constant'

export function handleClickOutsideScrollbar (status) {
  switch (status) {
    case 'open':
      document.addEventListener('click', handle)
      break
    case 'close':
      document.removeEventListener('click', handle)
  }
}

function handle () {
  document.querySelector('iframe#grattisWidgetScrollBar').contentWindow.postMessage({
    type: constants.WIDGET_BAR_IFRAME.SCROLL,
    clicked: true
  }, '*')
}
