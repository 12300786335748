import eventLoadScrollBar from './event-load-scroll-bar'
import eventFirstClick from './event-first-click'
import eventRunFullscreen from './event-click-for-fullscreen'
import eventJivo from './event-jivo'

export default {
  eventLoadScrollBar,
  eventFirstClick,
  eventRunFullscreen,
  eventJivo
}
